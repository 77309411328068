"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Feature = void 0;
var Feature;
(function (Feature) {
    Feature["NoArcadeBranding"] = "No Arcade Branding";
    Feature["Analytics"] = "Analytics";
    Feature["PublishEventsToHost"] = "Publish Events to Host";
    Feature["DisableIPTracking"] = "Disable IP Tracking";
    Feature["PreventIndexing"] = "Prevent Indexing";
    Feature["DoNotTrack"] = "Do Not Track";
    Feature["DefaultShareType"] = "Default Share Type";
    Feature["DisableInternal3rdPartyTrackers"] = "Disable Internal 3rd Party Trackers";
    Feature["PaywallArcadeLimit"] = "Paywall Arcade Limit";
    Feature["FlowInsights"] = "Flow Insights";
    Feature["EditAccessToArcadesIsControlled"] = "Edit Access to Arcades is Controlled";
    Feature["AllowMoreThanFiveButtons"] = "Allow More than Five Buttons in Overlays";
    Feature["DomCapture"] = "DomCapture";
    Feature["DomCaptureDefaultOn"] = "DomCapture Default On";
    Feature["EnableHighlightInImageEditor"] = "Enable Highlight in Image Editor";
    Feature["Commenting"] = "Commenting";
    // Temporary features:
    // To disable a temporary feature:
    // - Remove it from the code
    // - Remove it from Firestore (userProfiles, Teams and Global)
    // - Remove it from Retool
    Feature["Workspaces"] = "Workspaces";
})(Feature || (exports.Feature = Feature = {}));
