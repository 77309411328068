import { Feature } from '@arcadehq/shared/types'
import { PublicFlowLikeEntityData } from 'src/components/Flows/types'

import { AccountCore } from '../AccountCore'

export type Flows = ReturnType<typeof flows>

export const flows = (core: AccountCore) => ({
  isCreator(flow: PublicFlowLikeEntityData) {
    return flow.createdBy === core.user.id
  },

  isCollaborator(flow: PublicFlowLikeEntityData) {
    return flow.editors?.includes(core.user.id)
  },

  mayEdit(flow: PublicFlowLikeEntityData) {
    if (core.workspace) {
      return (
        this.isCreator(flow) ||
        this.isCollaborator(flow) ||
        (this.belongsToUserTeam(flow) &&
          !core.team.getFeature(Feature.EditAccessToArcadesIsControlled) &&
          !!core.access.isActiveMemberOfTeam)
      )
    }

    // TODO:workspaces remove below
    return (
      this.isCreator(flow) ||
      this.isCollaborator(flow) ||
      (this.belongsToUserTeam(flow) &&
        !core.team.getFeature(Feature.EditAccessToArcadesIsControlled) &&
        !!core.access.isActiveMemberOfTeam &&
        core.user.activeTeamId === flow.group)
    )
  },

  mayToggleFlowPublished(flow: PublicFlowLikeEntityData) {
    if (core.workspace) {
      return this.isCreator || core.access.isTeamAdmin
    }

    // TODO:workspaces remove below
    return this.isCreator || (core.access.isTeamAdmin && flow.belongsToTeam)
  },

  belongsToUserTeam(
    flow: Pick<PublicFlowLikeEntityData, 'belongsToTeam' | 'group' | 'teamId'>
  ) {
    if (core.workspace) {
      // TODO:workspaces remove check for teamId
      return !!flow.teamId && core.access.isPartOfTeam(flow.teamId)
    }
    // TODO:workspaces remove below
    return !!(
      flow.belongsToTeam &&
      (flow.group === core.user.emailGroup ||
        flow.group === core.user.activeTeamId ||
        (core.user.isExternalMemberOfTeam &&
          flow.group === core.team.domain &&
          flow.belongsToTeam))
    )
  },

  mayPreview(flow: PublicFlowLikeEntityData) {
    // TODO:workspaces figure out how to handle previewing
    return (
      this.mayEdit(flow) ||
      this.belongsToUserTeam(flow) ||
      core.user.isArcadeEmployee
    )
  },

  mayRequestEditAccess(flow: PublicFlowLikeEntityData) {
    // TODO:workspaces figure out how to handle edit request
    return (
      !this.mayEdit(flow) &&
      this.mayPreview(flow) &&
      flow.belongsToTeam &&
      !!core.team.getFeature(Feature.EditAccessToArcadesIsControlled)
    )
  },

  mayViewInsights(flow: PublicFlowLikeEntityData) {
    // TODO:workspaces figure out how to handle insights
    return this.mayEdit(flow) || (this.mayPreview(flow) && flow.belongsToTeam)
  },

  get showInsightsPaywall() {
    // This is necessary because the share page is static
    // and the data is loaded on the client
    if (!core.isFullyLoaded) {
      return false
    }

    if (core.subscription.isGrowth || core.subscription.isEnterprise)
      return false
    if (core.user.getFeature(Feature.FlowInsights)) return false
    return true
  },

  mayShare(flow: PublicFlowLikeEntityData) {
    return this.mayPreview(flow)
  },

  get publishingLimitDoesApply() {
    return core.subscription.freePlanLimitsApply
  },

  get publishingLimit() {
    return 3
  },

  mayPublishAdditionalFlows(personalPublishedFlowsCount: number) {
    if (this.publishingLimitDoesApply) {
      return personalPublishedFlowsCount < this.publishingLimit
    }
    return true
  },

  get mayUseImageEditor() {
    return !core.subscription.isFree
  },

  get mayUseHTMLEditor() {
    return core.subscription.isGrowth || core.subscription.isEnterprise
  },

  get mayUsePanAndZoom() {
    return !core.subscription.isFree
  },

  get mayUseFolders() {
    return core.subscription.isGrowth || core.subscription.isEnterprise
  },

  get mayUseAutoplay() {
    return !core.subscription.isFree
  },

  get mayUseViewerDemoMode() {
    return !core.subscription.isFree
  },

  get mayUseChooseYourOwnAdventure() {
    return core.subscription.isGrowth || core.subscription.isEnterprise
  },

  get mayUseCYAWithFivePlusButtons() {
    return (
      (core.subscription.isGrowth || core.subscription.isEnterprise) &&
      !!core.getFeature(Feature.AllowMoreThanFiveButtons)
    )
  },

  // This feature is deprecated, its only available for allowlisted teams who
  // wanted it back
  get mayUseHighlightInImageEditor(): boolean {
    return !!core.getFeature(Feature.EnableHighlightInImageEditor)
  },

  get mayUseCustomVariables(): boolean {
    return core.subscription.isGrowth || core.subscription.isEnterprise
  },

  folderFor(flow: PublicFlowLikeEntityData) {
    if (this.belongsToUserTeam(flow) && !this.isCollaborator(flow)) {
      return {
        name: core.team.name ?? '',
        path: '/flows/team',
      }
    }
    if (this.isCollaborator(flow)) {
      return {
        name: 'Shared with me',
        path: '/flows/shared',
      }
    }
    return {
      name: 'My Workspace',
      path: '/flows',
    }
  },
})
